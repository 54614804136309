<template>
    <hr class="footer-divider" />
    <footer class="equal-small-2 footer">
        <div class="row">
            <div class="col-1200-3 col-800-4 col-640-6 col-560-12">
                <address>
                    <p class="equal-small-2--bold">Luma Beef AG</p>
                    <p>Gewerbestrasse 6</p>
                    <p>8212 Neuhausen am Rheinfall</p>
                    <ClientOnly>
                        <p>
                            <a
                                :href="
                                    'mailto:' +
                                    auth?.settings?.help_email?.trim()
                                "
                                >E-Mail</a
                            >
                        </p>
                        <p>
                            <a
                                :href="
                                    'tel:' + auth?.settings?.help_phone?.trim()
                                "
                                >{{ auth?.settings?.help_phone }}</a
                            >
                        </p>
                    </ClientOnly>
                </address>
            </div>
            <ClientOnly>
                <div
                    class="col-1200-3 col-800-4 col-640-6 col-560-12 mt-5 mt-640-0"
                >
                    <p v-for="link in leftLinks">
                        <NuxtLink :to="link.url">{{ link.label }}</NuxtLink>
                    </p>
                </div>
                <div class="col-1200-3 col-800-4 col-560-12 mt-5 mt-800-0">
                    <p v-for="link in rightLinks">
                        <NuxtLink :to="link.url">{{ link.label }}</NuxtLink>
                    </p>
                    <p>
                        <a
                            class="cookiefirst-handler"
                            @click="handleCookiefirst()"
                        >
                            {{ t('footer.privacy_settings') }}
                        </a>
                    </p>
                </div>
            </ClientOnly>
            <div class="col-1200-3 col-800-12 col-560-12">
                <div class="icon-list icon-list--payments">
                    <IconsCreditCardMaster />
                    <IconsCreditCardVisa />
                    <IconsTwint />
                </div>
            </div>
        </div>
        <div class="newsletter-social-media">
            <div class="icon-list">
                <ClientOnly>
                    <NuxtLink
                        target="_blank"
                        v-if="getSetting('facebook', locale)"
                        :to="getSetting('facebook', locale)"
                        ><IconsFacebook width="28px"
                    /></NuxtLink>
                    <NuxtLink
                        target="_blank"
                        v-if="getSetting('instagram', locale)"
                        :to="getSetting('instagram', locale)"
                        ><IconsInstagram width="28px"
                    /></NuxtLink>
                    <NuxtLink
                        target="_blank"
                        v-if="getSetting('youtube', locale)"
                        :to="getSetting('youtube', locale)"
                        ><IconsYoutube width="28px"
                    /></NuxtLink>
                </ClientOnly>
            </div>
            <FormButton
                class="l-button--secondary l-button--auto-width"
                size40
                label="Newsletter"
                to="https://mailchi.mp/luma-delikatessen.ch/anmeldung"
                component="NuxtLink"
            >
                <template #iconAfter>
                    <IconsArrowExternal />
                </template>
            </FormButton>
        </div>
        <div class="copyright">
            <p>© {{ new Date().getFullYear() }} Luma Beef AG</p>
        </div>
    </footer>
</template>

<script setup>
import { useAuthStore } from '~/stores/auth';

const auth = useAuthStore();
function handleCookiefirst() {
    window.cookiefirst_show_settings();
}
const { t, locale } = useI18n();

function getSetting(platform, language) {
    if (!settings.value) return;
    try {
        return settings.value[`${platform}_${language}`];
    } catch {
        return;
    }
}

const settings = computed(() => {
    return auth?.settings;
});

const leftLinks = computed(() => {
    return auth?.settings?.footer
        ? auth?.settings?.footer.filter((x) => x.side === 'left')
        : [];
});
const rightLinks = computed(() => {
    return auth?.settings?.footer
        ? auth?.settings?.footer.filter((x) => x.side === 'right')
        : [];
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.footer-divider {
    color: $color-dark-01;
    opacity: 1;
    margin: 0;
}

.cookiefirst-handler {
    cursor: pointer;
}

.footer {
    margin-top: 98px;

    p {
        margin-bottom: 13px;
    }
    a {
        color: $color-light;
        text-decoration: none;
    }

    .icon-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        svg {
            margin-left: 11px;
            border-radius: 5px;
        }

        &--payments {
            @include media-breakpoint-down(1200) {
                justify-content: flex-start;
                margin-top: 60px;

                :first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .newsletter-social-media {
        margin-top: 78px;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(640) {
            margin-top: 60px;
            gap: 60px;
            flex-direction: column;
            align-items: start;
        }

        .icon-list svg {
            margin-left: 0;
            margin-right: 25px;
            fill: $color-light;
        }
    }

    .copyright {
        display: flex;
        justify-content: space-between;
        margin-top: 97px;
        @include media-breakpoint-down(640) {
            flex-direction: column;
        }
        p,
        a {
            color: $color-disabled;
        }
    }
}
</style>
