<template>
    <svg
        viewBox="0 0 28 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M11.0767383,13.9414785 L11.0767383,5.93290464 L18.5169587,9.85693634 L11.0767383,13.9414785 Z M27.7210288,4.31483974 C27.7210288,4.31483974 27.447004,2.35481781 26.6081119,1.49244803 C25.5436687,0.358905339 24.3496326,0.352923583 23.8025721,0.287124271 C19.8841153,0 14.0059356,0 14.0059356,0 L13.9940644,0 C13.9940644,0 8.11687394,0 4.19841718,0.287124271 C3.6513567,0.352923583 2.45830978,0.358905339 1.39287733,1.49244803 C0.553985302,2.35481781 0.27996043,4.31483974 0.27996043,4.31483974 C0.27996043,4.31483974 0,6.61482478 0,8.91680375 L0,11.0742236 C0,13.3752056 0.27996043,15.6761876 0.27996043,15.6761876 C0.27996043,15.6761876 0.553985302,17.6362096 1.39287733,18.4985793 C2.45830978,19.6311251 3.85712267,19.5962315 4.48035613,19.7148696 C6.72102883,19.9332037 14.0009893,20 14.0009893,20 C14.0009893,20 19.8841153,19.9910274 23.8025721,19.7029061 C24.3496326,19.6371068 25.5436687,19.6311251 26.6081119,18.4985793 C27.447004,17.6362096 27.7210288,15.6761876 27.7210288,15.6761876 C27.7210288,15.6761876 28,13.3752056 28,11.0742236 L28,8.91680375 C28,6.61482478 27.7210288,4.31483974 27.7210288,4.31483974 L27.7210288,4.31483974 Z"
        ></path>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
